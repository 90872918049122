<template>
  <a
    v-if="as === 'a'"
    :class="{ disabled }"
    :tabindex="disabled ? '-1' : undefined"
    :href="disabled ? undefined : parseUrl(1)"
    @click="onClick"
  >
    <slot />
  </a>
  <router-link
    v-else
    v-slot="{ navigate }"
    :to="parseUrl()"
    custom
  >
    <a
      v-if="disabled"
      :class="{ disabled }"
      tabindex="-1"
    >
      <slot />  
    </a>
    <a
      v-else
      :href="parseUrl(1)"
      @click="navigate"
    >
      <slot />
    </a>
  </router-link>
</template>

<script>
export default {
  props: {
    as: {
      type: String,
      default: 'RouterLink',
    },
    to: {
      type: [Object, String],
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    parseUrl(str) {
      const { workspace_id, business_id } = this.$route.query
      const { to } = this
      const query = { ...(to.query || {}), workspace_id, business_id }
      if (str) {
        const parseQuery = Object.entries(query).filter(val => val[1]) // { workspace_id, business_id }
        return parseQuery.length ? to + '?' + parseQuery.map(([key, val]) => `${key}=${val}`).join('&') : to
      }

      // const loc = typeof props.to === 'string' ? { path: props.to, query: {} }  :  { ...props.to, query: { ...(props.to.query || {})}}
      return typeof to === 'string' ? { path: to, query } : { ...to, query }
    },
    onClick(e) {
      if (this.disabled) {
        e.preventDefault()
        e.stopPropagation()
        return
      }
      this.$emit('click', e)
    },
  },
}
</script>

<template>
  <!-- :keyboard="!loading && !loadingStart" -->
  <!-- :mask-closable="!loading && !loadingStart" -->
  <a-modal
    centered
    :closable="loadingStart"
    :footer="null"
    :width="450"
    :keyboard="false"
    :mask-closable="false"
    :visible="visible"
    @cancel="$emit('cancel')"
  >
    <div class="px-3">
      <div v-if="loadingStart" class="text-center py-5">
        <a-icon
          spin
          type="loading"
          class="text-danger"
          style="font-size: 4rem"
        />
      </div>
      <template v-else>
        <div class="text-center px-2">
          <div class="d-inline-flex justify-content-center align-items-center rounded-circle wrap-icon">
            <a-icon v-if="dataUsed.length" type="close" class="text-white" style="font-size: 3rem" />
            <svg
              v-else
              width="3rem"
              viewBox="0 0 16 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path fill="#fff" fill-rule="evenodd" clip-rule="evenodd" d="M14.2088 8.22907C14.1979 7.65106 13.6947 7.21097 13.1179 7.24882C10.2512 7.43691 5.74876 7.43691 2.88214 7.24882C2.30527 7.21097 1.80206 7.65106 1.7912 8.22907C1.70943 12.5815 2.00125 18.0316 2.66667 19.1019C3.85185 21.0083 12.1481 21.0083 13.3333 19.1019C13.9987 18.0316 14.2906 12.5815 14.2088 8.22907ZM6.09524 10.4682C6.26455 11.3159 6.26455 15.4692 6.09524 16.3169C5.92593 17.1645 4.74074 17.1645 4.57143 16.3169C4.40212 15.4692 4.40212 11.3159 4.57143 10.4682C4.74074 9.62059 5.92593 9.62059 6.09524 10.4682ZM11.4286 16.3169C11.5979 15.4692 11.5979 11.3159 11.4286 10.4682C11.2593 9.62059 10.0741 9.62059 9.90476 10.4682C9.73545 11.3159 9.73545 15.4692 9.90476 16.3169C10.0741 17.1645 11.2593 17.1645 11.4286 16.3169Z" />
              <path fill="#fff" d="M10.6666 3.21994C10.6666 3.21389 10.6667 3.20784 10.6667 3.20178C10.6667 1.72716 9.47276 0.531738 8 0.531738C6.52724 0.531738 5.33333 1.72716 5.33333 3.20178C5.33333 3.20784 5.33335 3.21389 5.33339 3.21994C2.98511 3.25314 1.89015 3.33185 1.26984 3.45607C-0.423281 3.79512 -0.42328 6.16849 1.26984 6.50754C2.96296 6.8466 13.037 6.8466 14.7302 6.50754C16.4233 6.16849 16.4233 3.79512 14.7302 3.45607C14.1099 3.33185 13.0149 3.25314 10.6666 3.21994Z" />
            </svg>
          </div>
          <template v-if="dataUsed.length">
            <h5 class="my-3">
              {{ type }} {{ $t('price_setting.cant_delete') }}
            </h5>
            <p class="text-muted px-5">
              {{ type }} <b class="text-danger">{{ varname }}</b> {{ $t('price_setting.used_msg', { type: ['Variabel','Variable'].includes(type) ? 'formula' : 'produk' }) }}:
            </p>
            <ol
              class="text-left border shadow-sm rounded pt-2 pb-1 pl-4 pr-2 mb-2 overflow-auto"
              style="max-height: 130px"
            >
              <li
                v-for="(item, itemIdx) in dataUsed"
                :key="item.id + itemIdx"
                class="mb-1"
              >
                {{ item.name || item.title }}
              </li>
            </ol>
          </template>
          <template v-else>
            <h5 class="my-3">
              {{ $t('price_setting.confirm_delete_msg', { type }) }} <b>{{ varname }}</b> ?
            </h5>
            <p class="text-muted px-5">
              {{ $t('price_setting.confirm_delete_info', { type }) }}
            </p>
          </template>
        </div>

        <div class="d-flex flex-column px-2">
          <a-button
            v-if="!dataUsed.length"
            class="mt-2"
            size="large"
            type="primary"
            :loading="loading"
            @click="$emit('ok')"
          >
            {{ $t('utils.delete') }}
          </a-button>
          <a-button
            :type="dataUsed.length ? 'primary' : 'default'"
            size="large"
            class="mt-2"
            :disabled="loading"
            @click="$emit('cancel')"
          >
            {{ $t(`utils.${dataUsed.length ? 'close' : 'cancel'}`) }}
          </a-button>
        </div>
      </template>
    </div>
  </a-modal>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingStart: {
      type: Boolean,
      default: false,
    },
    varname: {
      type: String,
      default: () => '',
    },
    dataUsed: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: () => '',
    },
  },
  emits: ['ok', 'cancel'],
}
</script>

<style lang="scss" scoped>
.wrap-icon {
  width: 90px;
  height: 90px;
  background: #FF0000; // For not support linear-gradient
  background: linear-gradient(153.43deg, #FF8585 0%, #FF0000 83.33%)
}
</style>